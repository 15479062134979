const mapGlobalAppConfig = (config = {}) => ({
  ...config,
  appName: config.app_name,
  authType: config.auth_type,
  authWebUrl: config.auth_webauth_url,
  brandImage: config.brand_image,
  realmConfig: {
    realmAppId: config.realm_app,
    databaseName: config.realm_db,
  },
  paywallArtWork: config.paywall_artwork,
  theme: {
    colors: {
      primaryColor: config.primary_color,
      primaryOnColor: config.primary_on_color,
      primaryMutedColor: config.primary_muted_color,
      secondaryColor: config.secondary_color,
      secondaryOnColor: config.secondary_on_color,
      errorColor: config.error_color,
      errorOnColor: config.error_on_color,
      successColor: config.success_color,
      successOnColor: config.success_on_color,
      backdropColor: config.backdrop_color,
      backdropOnColor: config.backdrop_on_color,
      backdropMutedColor: config.backdrop_muted_color,
      surfaceColor: config.surface_color,
      surfaceOnColor: config.surface_on_color,
      surfaceMutedColor: config.surface_muted_color,
      borderColor: config.border_color,
      separatorColor: config.separator_color,
      cornerRadius: config.corner_radius,
      cornerRadiusSmall: config.corner_radius_small,
      cornerRadiusButton: config.corner_radius,
      cornerRadiusButtonSmall: config.corner_radius_small,
    },
  },
  playback: {
    displayQueue: config.playback?.display_queue || true,
    displayQueueControls: config.playback?.display_queue_controls || true,
    displayTimeControls: config.playback?.display_time_controls || true,
    displayBookmarks: config.playback?.display_bookmarks || false,
    displayPlaybackRate: config.playback?.display_playback_rate || true,
  },
  onboardingPages: config.onboarding_pages,
  tabs: config.tabs.map((tab) => ({
    target: tab?.target,
    targetId: tab?.target_id,
    label: tab?.label,
    children: tab?.children?.map((subtab) => ({
      target: subtab?.target,
      targetId: subtab?.target_id,
      label: subtab?.label,
    })),
  })),
  termsLink: config.terms_link,
  locales: config.locales,
  firebaseConfig: config.firebase_config,
  overwriteTranslations: config.string_overrides,
  isAutoOpenPlayerFullScreen: config.player_auto_open ?? false,
  firebaseFunctionLocation: config.firebase_function_location,
  stripePublishableKey: config.stripe_publishable_key,
  stripePricingTableId: config.stripe_pricing_table_id,
  stripePricingTableIdPostTrial: config.stripe_pricing_table_id_post_trial,
});

export default mapGlobalAppConfig;
